import { extendTailwindMerge } from 'tailwind-merge'
export function classNames(...classes: any[string]) {
  return classes.filter(Boolean).join(' ')
}

export const customTwMerge = extendTailwindMerge({
  classGroups: {
    boxShadow: [{ shadow: ['none', 'offset'] }],
  },
})
